$(document).ready(function () {

    var marker, map_style = [{ "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.line", "elementType": "geometry.fill", "stylers": [{ "color": "#e04e71" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }];

    $('[data-toggle="tooltip"]').tooltip();

    // Dropdown menus
    $('.dropdown .dropdown-menu').on('click', '.dropdown-item', function (e) {
        e.preventDefault();
        var link_text = $(this).html(),
            link_val = $(this).data('value');
        $(this).parent().siblings('button').html(link_text);
        $(this).parent().siblings('input[type=hidden]').val(link_val);
    });
    
    // Dropdown menus with search
    $('.dropdown').on('click', 'button', function() {
        
        var search = $(this).siblings('.dropdown-menu').find('input[type=search]'),
            opts   = $(this).siblings('.dropdown-menu').find('a');
        setTimeout(function() { search.focus(); }, 300);
        opts.show();
        
        search.on('keypress', function(e) {
            
            var keyword = $(this).val();
            opts.hide();
            opts.each(function() {
                if($(this).html().toLowerCase().indexOf(keyword.toLowerCase()) !== -1) {
                    $(this).show();
                }               
            });
            
            // If there is only one visible result
            if(opts.filter(':visible').length === 1) {
                var keycode = e.keyCode ? e.keyCode : e.which;
                if(keycode === 13) {
                    var result = opts.filter(':visible').first(),
                        link_text = result[0].innerHTML,
                        link_val = result[0].dataset.value;
                    $(this).parent().siblings('button').html(link_text);
                    $(this).parent().siblings('input[type=hidden]').val(link_val);
                }
            }            
            
        });
    });

    $('.dropdown .dropdown-team').on('click', '.dropdown-item', function (e) {
        e.preventDefault();
        location.href = location.href + ($(this).data('value') === 'all' ? 'our-team' : 'services/?service=' + $(this).data('value'));
    });

    // Download E-brochures link
    $('header').on('click', '.header__brochures', function (e) {

        e.preventDefault();

        if ($('.header__brochures--container').hasClass('open')) {

            $(this).find('span').html('Download E-brochures');
            $(this).find('i').removeClass('fa-times').addClass('fa-angle-double-down');
            $('.header__brochures--container').removeClass('open');

        } else {

            $(this).find('span').html('Close Downloads');
            $(this).find('i').removeClass('fa-angle-double-down').addClass('fa-times');
            $('.header__brochures--container').addClass('open');

        }

    });

    $('.search__link').on('click', function (e) {
        e.preventDefault();
        if ($('form.search').not(':visible')) {
            $('form.search').fadeIn(250);
            $('body').addClass('open');
            $('header nav .search__link').addClass('active');
        }

        $('form.search').on('click', '.search__close', function (e) {
            e.preventDefault();
            $('form.search').fadeOut(200);
            $('body').removeClass('open');
            $('header nav .search__link').removeClass('active');
        });

    });

    // Slide down sections
    $('.slide:not(.no-slide-up) figure').on('click', '> a', function (e) {
        e.preventDefault();
        $(this).find('figcaption').toggleClass('open');
    });

    // View Service button on home page
    $('.home__our-services').on('click', '.view-service', function (e) {
        e.preventDefault();
        window.location = '/services/?service=' + $('.home__our-services .dropdown input[type=hidden]').val();
    });

    // View team button on home page
    $('.home__our-services').on('click', '.view-team', function (e) {
        e.preventDefault();
        window.location = '/our-team/?sector=' + $('.home__our-services .dropdown input[type=hidden]').val();
    });

    // View Service button on Services page
    $('.home__our-services').on('click', '.show-service', function (e) {
        var service = $('.home__our-services .dropdown input[type=hidden]').val();
        e.preventDefault();
        $('.our_services__service').hide();
        $('.our_services__service--' + service).slideDown(500);
        $('html, body').animate({
            scrollTop: $('.our_services__service--' + service).offset().top - 109
        }, 1000);
    });

    $('.service-icons.service-page').on('click', '.service-icon', function (e) {
        e.preventDefault();
        var service = $(this).data('value');
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
        $('.our_services__service').hide();
        $('.our_services__service--' + service).slideDown(500);
        $('html, body').animate({
            scrollTop: $('.our_services__service--' + service).offset().top - 109
        }, 1000);

    });

    // Case studies
    $('.case_study__card').on('click', function() {
        $(this).toggleClass('open');
    });

    // Filtering team members
    $('.our-team__filters').on('click', 'a', function (e) {

        e.preventDefault();

        // Remove active from all so only one can be selected
        $('.our-team__filters .btn').removeClass('active');
        $(this).addClass('active');

        $('.our-team__members .member').hide();

        // If no filters selected, show all team members
        if ($('.our-team__filters .btn.active').length === 0 || $(this).data('filter') === 'all') {
            $('.our-team__members .member').show();
        } else {

            // For each filter selected
            var filter;
            $('.our-team__filters .btn.active').each(function () {

                filter = $(this).data('filter');
                $('.our-team__members .member').each(function () {
                    if ($(this).hasClass(filter)) {
                        $(this).show();
                    }
                });

            });

        }

    });

    $('.bxslider').bxSlider({
        auto: true,
        pager: true,
        controls: false,
        mode: 'fade',
        speed: 1000,
        pause: 7000
    });

    // Arrow to take you further down homepage
    $('.home-carousel__arrow').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $('.teams-panel').offset().top - 170
        }, 1000);
    });
    
    $('.carousel').bcSwipe({ threshold: 50 });







    // Shortcut function for updating results
    function updateResults(request, page, page_size, update_x_of_y, update_total) {

        var filters = [];
        $('.property-search__results-filters a.active').each(function () {
            filters.push($(this).data('filter'));
        });

        var selector = (request === 'schemes_search' ? 'schemes' : 'properties');
        $('.property-search__results > div').hide();
        $('.property-search__' + selector).show();

        $.ajax({
            method: "POST",
            url: "/ajax.php",
            data: {
                request: request,
                page: page,
                page_size: page_size,
                filters: filters,
                rent_sale: $('.search-form--property [name=rent-sale]').val(),
                property_type: $('.search-form--property [name=property-type]').val(),
                town: $('.search-form--property [name=town]').val(),
                keyword: $('.search-form--property [name=keyword]').val(),
                min_size: $('.search-form--property [name=min-size]').val(),
                max_size: $('.search-form--property [name=max-size]').val(),
                min_rent_price: $('.search-form--property [name=min-rent-price]').val(),
                max_rent_price: $('.search-form--property [name=max-rent-price]').val()
            },
            beforeSend: function () {
                $('.property-search__' + selector).html('<img src="/images/loading.svg" width="100" class="loading" alt="Loading...">');
            }
        }).done(function (response) {

            // Show results
            var results = response.split('|||');
            $('.property-search__' + selector).html(results[1]);

            // Attach pagination
            attachPagination();

            // Update "X-Y" text
            if (update_x_of_y) {
                var on_this_page = $('.property-search__' + selector + ' .property-search__result:visible').length,
                    shown = ((page * page_size) - (page_size - on_this_page));
                $('.xyz-text').html((((page - 1) * page_size) + 1) + '–' + shown);
            }

            // Update "of Z" text
            if (update_total) {
                $('.search-form__pagination--text .total-results').html(results[0]);
            }

        });

    }



    // Search page form search button
    /*$('.search-form__top').on('click', '.search-form__field--go', function(e) {
        e.preventDefault();
        var page_size = $('input[name=per-page]').val(),
            request = $('.search-form__button[data-results].active').data('results') == 'scheme' ? 'schemes_search' : 'properties_search';

        // Validate min-max values before submitting
        if (validateMinMax($('.min-size'), $('.max-size')) && validateMinMax($('.min-rent-price'), $('.max-rent-price'))) {
            updateResults(request, 1, page_size, true, true);
        }
    });*/



    // Switch property and scheme searches
    $('.search-form__top').on('click', '.search-form__button', function (e) {
        e.preventDefault();
        $('.search-form__top .search-form__button').removeClass('active');
        $(this).addClass('active');

        var results = $(this).data('results');

        $(this).siblings('input[type=hidden]').val(results);

    });



    // More options drop down
    $('.search-form__more-options--link').on('click', 'a', function (e) {

        e.preventDefault();
        $(this).toggleClass('active');

        var form = $('.search-form__more-options--form');
        if (form.is(':visible')) {
            form.slideUp(500);
        } else {
            form.slideDown(500);
        }

    });


    // Clicking on table row
    $('.property-search__results--table').on('click', 'tr[data-url]:not(.has-properties)', function(e) {
        e.preventDefault();
        window.location = $(this).data('url');
    });
    
    $('.property-search__results--table').on('click', 'tr.has-properties', function(e) {
        e.preventDefault();
        $('tr[id=properties_for_scheme_' + $(this).data('scheme') + ']').toggle().find('.scheme-properties-container').slideToggle();
    });
    
    

    // Per page dropdown
    $('.search-form__pagination--per-page').on('click', '.dropdown-item', function (e) {

        e.preventDefault();
        var page = 1,
            page_size = $(this).parent().siblings('[type=hidden]').val(),
            request = $('.search-form__button[data-results].active').data('results') == 'scheme' ? 'schemes_search' : 'properties_search';

        $('.page-size').html(page_size);

        updateResults(request, page, page_size, true, false);

    });


    if ($('#property-search__map').length) {

        var lat = parseFloat($('#property-search__map').data('lat')),
            lng = parseFloat($('#property-search__map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('property-search__map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        var markers = $('#property-search__map').data('markers'),
            infowindow = new google.maps.InfoWindow();

        // For each marker
        Object.keys(markers.results).map(function (key) {

            var data = markers.results[key];


            marker = new google.maps.Marker({
                position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
                map: results_map,
                title: data.name,
                icon: '/images/marker.png',
            });

            marker.addListener('click', function () {
                infowindow.setContent('<strong>' + data.name + '</strong><br>' + data.town + ', ' + data.postcode + '<br><a href="' + data.url + '">More information</a>');
                infowindow.open(results_map, this);
            });

            bounds.extend(marker.position);

        });

        results_map.fitBounds(bounds);
        google.maps.event.addListener(results_map, 'bounds_changed', function () {
            google.maps.event.trigger(results_map, 'resize');
        });

    }
    

    // Switch search results views
    /*$('.property-search__views').on('click', 'a[data-view]', function (e) {
        e.preventDefault();
        var view = $(this).data('view');
        $('.property-search__views a[data-view]').removeClass('active');
        $(this).addClass('active');

        $('.property-search__results .result-view').hide();
        $('.property-search__results--' + view).show();

        // If map view
        if (view == 'map') {

            if ($('#property-search__map').length) {

                var lat = parseFloat($('#property-search__map').data('lat')),
                    lng = parseFloat($('#property-search__map').data('lng')),
                    point = { lat: lat, lng: lng },
                    bounds = new google.maps.LatLngBounds();

                var results_map = new google.maps.Map(document.getElementById('property-search__map'), {
                    center: point,
                    zoom: 15,
                    scrollwheel: false,
                    styles: map_style
                });

                var markers = $('#property-search__map').data('markers'),
                    infowindow = new google.maps.InfoWindow();

                // For each marker
                Object.keys(markers.results).map(function (key) {

                    var data = markers.results[key];


                    marker = new google.maps.Marker({
                        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
                        map: results_map,
                        title: data.name,
                        icon: '/images/marker.png',
                    });

                    marker.addListener('click', function () {
                        infowindow.setContent('<strong>' + data.name + '</strong><br>' + data.town + ', ' + data.postcode + '<br><a href="' + data.url + '">More information</a>');
                        infowindow.open(results_map, this);
                    });

                    bounds.extend(marker.position);

                });

                results_map.fitBounds(bounds);
                google.maps.event.addListener(results_map, 'bounds_changed', function () {
                    google.maps.event.trigger(results_map, 'resize');
                });

            }

        }

    });*/



    // Search results filters
    /*$('.property-search__results-filters').on('click', 'a[data-filter]', function(e) {

        e.preventDefault();

        if($(this).data('filter') == 'all') {
            $('.property-search__results-filters a[data-filter]').removeClass('active');
        } else {
            $('.property-search__results-filters a[data-filter=all]').removeClass('active');
        }

        $(this).toggleClass('active');

        var page_size = $('input[name=per-page]').val(),
            request = $('.search-form__button[data-results].active').data('results') == 'scheme' ? 'schemes_search' : 'properties_search';

        updateResults(request, 1, page_size, false, true);

    });*/



    // Pagination of search results
    function attachPagination() {
        $('.search-form__pagination--links').on('click', 'a[data-page]', function (e) {

            e.preventDefault();
            var page = $(this).data('page'),
                page_size = $('input[name=per-page]').val(),
                request = $('.search-form__button[data-results].active').data('results') == 'scheme' ? 'schemes_search' : 'properties_search';

            updateResults(request, page, page_size, true, false);

        });
    }
    attachPagination();




    // Sticky scheme menu
    $(window).on('scroll', function (e) {

        var height = $(window).width() <= 600 ? 305 : 588;

        if ($(window).scrollTop() > height) {
            $('.scheme__navigation').addClass("sticky");
        } else {
            $('.scheme__navigation').removeClass("sticky");
        }

    });

    // Scheme page map
    if ($('#scheme__map').length) {

        var lat = parseFloat($('#scheme__map').data('lat')),
            lng = parseFloat($('#scheme__map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('scheme__map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: results_map,
            title: 'EYCO',
            icon: '/images/marker.png',
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(results_map, 'bounds_changed', function () {
            google.maps.event.trigger(results_map, 'resize');
        });

    }

    // Contact us page map
    if ($('#contact__hero--map').length) {

        var lat = parseFloat($('#contact__hero--map').data('lat')),
            lng = parseFloat($('#contact__hero--map').data('lng')),
            point = { lat: lat, lng: lng },
            bounds = new google.maps.LatLngBounds();

        var contact_map = new google.maps.Map(document.getElementById('contact__hero--map'), {
            center: point,
            zoom: 9,
            scrollwheel: false,
            styles: map_style
        });

        marker = new google.maps.Marker({
            position: { lat: parseFloat(lat), lng: parseFloat(lng) },
            map: contact_map,
            title: 'EYCO',
            icon: '/images/contact_marker.png',
        });

        bounds.extend(marker.position);
        marker2 = new google.maps.Marker({
            position: { lat: parseFloat(55.8636495), lng: parseFloat(-4.266446) },
            map: contact_map,
            title: 'EYCO',
            icon: '/images/contact_marker.png',
        });

        bounds.extend(marker2.position);
        google.maps.event.addListener(contact_map, 'bounds_changed', function () {
            google.maps.event.trigger(contact_map, 'resize');
        });

    }

    function validateMinMax(min, max) {
        if ($(min).val() > $(max).val()) {
            $(min).addClass('error').after("<span class='input-error'>Min cannot be higher than max</span>");
            return false;
        }
        return true;
    }

    // Perform quick validation on min-max fields on property search
    $('.search').submit(function (e) {
        $('.input-error').hide();
        $('.form-control').removeClass('error');

        if (validateMinMax($('.min-size'), $('.max-size')) && validateMinMax($('.min-rent-price'), $('.max-rent-price'))) {
        } else {
            e.preventDefault();
        }

    });

    // Handle hover color-change on scheme list view
    $('.listview__image').hover(function () {
        let schemeId = $(this).data('schemeid');
        $(`h4.header-${schemeId}`).addClass('blue');
    });

    $('.listview__image').mouseleave(() => {
        $('h4').removeClass('blue');
    });


    // "Email me details about this property" button
    $('.email-property-send').on('click', function (e) {

        e.preventDefault();
        
        var name = $('input[name=email-property-name]').val(),
            email = $('input[name=email-property-address]').val(),
            property = $('input[name=email-property-id]').val();

        if(name.length == 0 || email.length == 0) {
            
            $('.scheme__buttons .message').html('<span style="color:#C00">You must enter your name and email address.</span>');
            
        } else {
        
            // Make the request to build the markup
            $.ajax({
                method: "POST",
                url: "https://email-eyco.delta.coghub.net/ajax.php",
                data: {
                    constantcontact: 'false',
                    type: 'single',
                    properties: property,
                    name: name,
                    email: email,
                    send: 'true',
                    preview: 'false'
                }
            }).done(function(result) {
                $('input[name=email-property-name]').val('');
                $('input[name=email-property-address]').val('');
                $('.scheme__buttons .message').html(result);
            });
            
        }

    });

    // News page "per page" dropdown
    $('.dropdown.search-form__pagination--per-page').on('click', '.dropdown-item', function (e) {
        e.preventDefault();
        window.location = $(this).attr('href');
    });

    $(".hamburger").click(function () {
        $(this).toggleClass("is-active");
        $('.header__nav').slideToggle();
        if($('.header__brochures--container').hasClass('open')) {
            $('.header__brochures').trigger('click');
        }
    });

    $('.footer__mobile__toggle').click(function () {
        $(this).toggleClass('open');
        $('.footer-contents').slideToggle();
    });
    
    // Change search view to 'list' for mobile users as table view does not work
    if($(window).width() <= 600) {
        $('form.search, form.search-form').find('input[type=hidden][name=view]').val('list');
    }

});
